<template>
  <div>
    <AttractionsTable></AttractionsTable>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AttractionsTable from "@/view/pages/attraction/components/AttractionsTable";

export default {
  name: "AttractionListings",
  components: {
    AttractionsTable
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "AttractionListings" }]);
  },
};
</script>